import styled from '@emotion/styled/macro';
import Chip from '../Atoms/Chip';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getDate from '../../shared/getDate';
import DateRangeFilter from './Molecules/DateRangeFilter';
import { modifyFilter } from '../../app/filterReducer';
import FilterFields from './Molecules/FilterFields';
import { useTheme } from '@mui/system';
import useMediaQuery from '../../hooks/useMediaQuery';
import isEmpty from '../../shared/isEmptyOject';
import SearchButton from './Molecules/SearchButton';
import useIsMd from '../../hooks/useIsMd';
import FilterFieldsMd from '../md/cirugias/Molecules/FilterFieldsMd';
import usePermissions from '../../hooks/usePermissions';
import { errorMessage } from '../../app/messagesReducer';






const Filtro = ({close})=>{

    const dispatch = useDispatch();
    const theme = useTheme();
    const isLaptop = useMediaQuery('laptop');
    const cronogramaChipColor = theme.palette.grey[800];


    const filter = useSelector(state=>state.filter.filter);
    const isMd = useIsMd();

    const [today, tomorrow, yesterday, startOfDay, endOfDay] = getDate(); //date are in JSON string due to redux limitation, does not allow for date objects in store

    const {infoAgendada, 
            infoRealizada,
            aceptada,
            solicitada,
            suspendida,
            preparada,
            despachada,
            entregada,
            retirada,
            recibida,
            pendiente,
            transferencia,
            entrega,
            revision,
            cambio,
            facturada,
            reporte,
            sinReporte,
            pendienteOperaciones,
            cerrada
        } = filter;

    const permissions = usePermissions()
    const {
        canViewFacturacionStages
    } = permissions

    const [startDate, handleStartDateChange] = React.useState(startOfDay(today));
    const [endDate, handleEndDateChange] = React.useState(endOfDay(tomorrow));
    const [openDatePicker, setOpenDatePicker] = React.useState();

    
    React.useEffect(()=>{
        //console.log(filter)
        //dispatch(fetchCirugias(filter));            
    },[filter, dispatch])


    
     const onClickCronogramaHandler = (infoAgendada, infoRealizada)=>{

        //when Por Agendar is clicked. FechaFilter cannot be sent to backend or else it will filter by date which is not possible in por agendar.
         if(!infoAgendada && !infoRealizada){  
            //selectOne with "none" assigns undefined to all date properties.  
            const selected = selectOne("none")
            dispatch(modifyFilter({...filter, infoAgendada,infoRealizada, ...selected}))   
            return              
         }
        
        dispatch(modifyFilter({...filter, infoAgendada,infoRealizada}))        
    }

    const selectOne =(select, value)=>{

        const filterObj = {
            today: undefined,
            tomorrow: undefined,
            yesterday: undefined,
            mesActual: undefined,
            mesAnterior: undefined,
            startDate: undefined,
            endDate: undefined
        }

        if(select==="none") return filterObj

        //if range selected return object with undefined
        if(select === "startDate"){
            if( typeof value === 'object'){
                return {...filterObj, startDate:value.startDate, endDate:value.endDate}
            }else{
                console.log("Error in function call, if start date selected, must provide object with start date and end date")
            }
            
        }

        for(let filter in filterObj){
            if(filter === select){
                filterObj[filter] = value
                
            }else{
                filterObj[filter] = undefined
            }
        }

        return filterObj

    }

    //console.log(selectOne("today", today))
    
    const onClickHandlerFecha = (fecha)=>{
        setOpenDatePicker(false);
        handleStartDateChange(startOfDay(today));
        handleEndDateChange(endOfDay(tomorrow));
        
        if(fecha==='all'){            
            const selected = selectOne("none")            
            dispatch(modifyFilter({...filter,...selected}))            
        }

        if(fecha==="today"){           
            const selected = selectOne("today", today)            
            dispatch(modifyFilter({...filter, ...selected}))            
        }

        if(fecha==="tomorrow"){            
            const selected = selectOne("tomorrow", tomorrow);            
            dispatch(modifyFilter({...filter, ...selected}));           
        }

        if(fecha==="yesterday"){            
            const selected = selectOne("yesterday", yesterday)            
            dispatch(modifyFilter({...filter, ...selected}));            
        }
        if(fecha==="mes actual"){            
            const selected = selectOne("mesActual", true);
            dispatch(modifyFilter({...filter, ...selected}))            
        }

        if(fecha==="mes anterior"){            
            const selected = selectOne("mesAnterior", true);
            dispatch(modifyFilter({...filter, ...selected}))            
        }
                      
    }

   
    const onClickAplicar = () => {
        // Check if startDate and endDate are valid dates
        if (startDate && endDate) {
          const startDateObj = new Date(startDate);
          const endDateObj = new Date(endDate);
      
          if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
            dispatch(errorMessage("Las fechas seleccionadas no son válidas"));
            return;
          }
      
          if (startDateObj > endDateObj) {
            dispatch(errorMessage("La fecha de inicio no puede ser mayor a la fecha final"));
            return;
          }
        } else {
          dispatch(errorMessage("Debe seleccionar una fecha de inicio y una fecha final"));
          return;
        }
      
        const selectedDates = {
          startDate: startDate.toString(),
          endDate: endDate.toString(),
        };
      
        const selected = selectOne("startDate", selectedDates);
        setOpenDatePicker(false);
        dispatch(modifyFilter({ ...filter, ...selected }));
      };

    const onClickStatus = (status)=>{        
        dispatch(modifyFilter({...filter, [status]:!filter[status]}));       
    }

    const onClickOperaciones = (status)=>{        
        dispatch(modifyFilter({...filter, [status]:!filter[status]}))        
    }

    const onClickReporte = (reporte)=>{
        if(reporte==="reporte"){            
            dispatch(modifyFilter({...filter, reporte: !filter.reporte, sinReporte: false}))
        }
        if(reporte==="sinReporte"){            
            dispatch(modifyFilter({...filter, reporte: false, sinReporte: !filter.sinReporte}));
        }
    }

    //properties that will be checked by isEmpty function.
    let properties = ["today", 'tomorrow', 'yesterday', 'mesActual', 'mesAnterior', 'startDate', 'endDate' ]         



    return (
        <Wrapper isLaptop={isLaptop}>
            <Item>
                <SearchButton/>
            </Item>
            <Item>
                <StyledLabel>Cronograma</StyledLabel>
                <ChipWrapperStatus>
                    <Chip color={cronogramaChipColor} role="button" outline ={infoAgendada || infoRealizada} name="Por Agendar" label="Por Agendar" onClick={(event)=>onClickCronogramaHandler(false, false)}/>
                    <Chip color={cronogramaChipColor} primary outline={!infoAgendada || infoRealizada} label="Agendadas" onClick={()=>onClickCronogramaHandler(true, false)}/>
                    <Chip color={cronogramaChipColor} primary outline={!infoRealizada} label="Realizadas" onClick={()=>onClickCronogramaHandler(true, true)}/>
                </ChipWrapperStatus>
                
            </Item>
            {
                filter.infoAgendada && !filter.infoRealizada && 

                <Item>
                    <StyledLabel>Status</StyledLabel>
                    <ChipWrapperStatus>
                        <Chip success outline={aceptada} label="Aceptadas" onClick={()=>onClickStatus('aceptada')}/>
                        <Chip success outline={solicitada} label="Solicitadas" onClick={()=>onClickStatus('solicitada')}/>
                        <Chip success outline={suspendida} label="Suspendidas" onClick={()=>onClickStatus('suspendida')}/>
                    </ChipWrapperStatus>
                    
                </Item>

            }
            {
                filter.infoAgendada  &&
                    <Item>
                        <StyledLabel>Fecha</StyledLabel>                                                
                        <Chip primary outline={!isEmpty(filter, properties) || openDatePicker} label="Todas" onClick={()=>onClickHandlerFecha('all')}/>
                        <Chip primary outline={!filter?.today || openDatePicker} label="Hoy" onClick={()=>onClickHandlerFecha('today')}/>
                        <Chip primary outline={!filter?.yesterday || openDatePicker} label="Ayer" onClick={()=>onClickHandlerFecha('yesterday')}/>
                        <Chip primary outline={!filter?.tomorrow || openDatePicker} label="Mañana" onClick={()=>onClickHandlerFecha('tomorrow')}/>
                        <Chip primary outline={!filter?.mesActual || openDatePicker} label="Mes Actual" onClick={()=>onClickHandlerFecha('mes actual')}/>
                        <Chip primary outline={!filter?.mesAnterior || openDatePicker} label="Mes Anterior" onClick={()=>onClickHandlerFecha('mes anterior')}/>
                        <Chip primary outline={!openDatePicker} label="Rango Fechas" onClick={()=>setOpenDatePicker(!openDatePicker)} />                
                        <DateRangeFilter
                            openDatePicker={openDatePicker}
                            fechaFilter={filter}
                            onClickAplicar={onClickAplicar}
                            startDate={startDate}
                            handleStartDateChange={handleStartDateChange}
                            endDate={endDate}
                            handleEndDateChange={handleEndDateChange}                            
                        />                        
                    </Item>
            }
            {
               filter.infoRealizada && !isMd &&
               <Item>
                    <StyledLabel>Reporte</StyledLabel>
                    <Chip primary outline={!reporte} label="Reporte" onClick={()=>onClickReporte('reporte')}/>
                    <Chip primary outline={!sinReporte} label="Sin Reporte" onClick={()=>onClickReporte('sinReporte')}/>                
                </Item>
            }

            { !isMd && 
                <Item>
                    <StyledLabel>Operaciones</StyledLabel>
                    <Chip primary outline={!pendienteOperaciones} label="Pendiente" onClick={()=>onClickOperaciones('pendienteOperaciones')}/>
                    <Chip primary outline={!preparada} label="Preparada" onClick={()=>onClickOperaciones('preparada')}/>
                    <Chip primary outline={!despachada} label="Despachada" onClick={()=>onClickOperaciones('despachada')}/>
                    <Chip primary outline={!entregada} label="Entregada" onClick={()=>onClickOperaciones('entregada')}/>
                    <Chip primary outline={!retirada} label="Retirada" onClick={()=>onClickOperaciones('retirada')}/>
                    <Chip primary outline={!recibida} label="Recibida" onClick={()=>onClickOperaciones('recibida')}/>
                </Item>
            }
            {filter.infoRealizada && !isMd && canViewFacturacionStages &&
                <Item>
                    <StyledLabel>Facturacion</StyledLabel>
                    <Chip primary outline={!pendiente} label="Pendiente" onClick={()=>onClickOperaciones('pendiente')}/>
                    <Chip primary outline={!transferencia} label="Transferencia" onClick={()=>onClickOperaciones('transferencia')}/>
                    <Chip primary outline={!entrega} label="Entrega" onClick={()=>onClickOperaciones('entrega')}/>
                    <Chip primary outline={!revision} label="Revision" onClick={()=>onClickOperaciones('revision')}/>
                    <Chip primary outline={!cambio} label="Cambio" onClick={()=>onClickOperaciones('cambio')}/>
                    <Chip primary outline={!cerrada} label="Cerrada" onClick={()=>onClickOperaciones('cerrada')}/>
                    <Chip primary outline={!facturada} label="Facturada" onClick={()=>onClickOperaciones('facturada')}/>
                </Item>
            }
            <Item>
                {!isMd ? <FilterFields close={close} />
                :
                    <FilterFieldsMd close={close} />
                }

            </Item>

        </Wrapper>
    )
}



const Wrapper = styled.div`
    width:100%;
    height: fit-content;
    display:flex;
    flex-direction: column;
    margin-bottom: 12px;
    flex:1;
    background-color: #ffff;
    box-shadow:${ p=>p.isLaptop? `0px 2px 1px -1px rgb(0 0 0 / 20%), 
    0px 1px 1px 0px rgb(0 0 0 / 14%), 
    0px 1px 3px 0px rgb(0 0 0 / 12%)`: 'revert'};
    border-radius: 10px;
    padding: 8px;
`

const ChipWrapperStatus = styled.div`
    display:flex;
    
    flex-wrap: wrap;

`

const Item = styled.div`
    padding: 12px 4px;
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    width:100%;
    
`

const StyledLabel = styled.p`
    margin: 0;
    margin-bottom:4px;
    padding:0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[600]}
`





export default Filtro;