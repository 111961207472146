import React from "react";
import styled from '@emotion/styled';
import DropDownButton from "../Atoms/MenuButton";
import Button from "../Atoms/Button";
import VerMenuButton from "./Molecules/VerMenuButton";
import { useNavigate } from 'react-router-dom'
import useCirugia from "../../hooks/useCirugia";
import ConsumoButtonGroup from "./Molecules/ConsumoButtonGroup";
import ResetMenuButton from "./Molecules/ResetMenuButton";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import IconButton from "../Atoms/IconButton";
import FileText from "../Icons/File-text";
import Truck from "../Icons/Truck";
import MapPin from "../Icons/MapPin";
import PermissionsContext from "../../context/permissions-context";
import usePermissions from "../../hooks/usePermissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faDolly } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/system";
import CheckCircle from "../Icons/CheckCircle";
import CopyCirugia from "./Molecules/CopyCirugia";
import MessageSquare from "../Icons/Message-square";
import useSubscriptionToken from "../../hooks/useSubscriptionToken";
import { Icon } from "@mui/material";
import Trash2 from "../Icons/Trash2";
import MessageCircle from "../Icons/Message-Circle";
import AlertOctagon from "../Icons/AlertOctagon";



const CirugiasActions = ({
    setOpenModal,    
    cirugiaId,
    setCirugiaId,
    form,
    setFormType,
    cirugia         
})=>{

    const permissions = usePermissions()

    const {
        canAgendar,
        canAceptarCirugias,
        canEditarCirugias,
        canSuspenderCirugias,
        canConcluirCirugias,        
        canEditReporte,
        canResetReporte,        
        canUpdateOperacionesStages,
        canUpdateFacturacionStages,
        canAutorizarCirugias,
        canAsignarEc,
        canCancelarCirugias,
        canReactivarCirugias,
        isMaster        
    }=permissions

    const navigate = useNavigate();

    const theme = useTheme();
    const _cirugia = useCirugia(cirugiaId);
    const user = useCurrentUser();
    const subscription = useSubscriptionToken();
    const agendada = cirugia?.info?.agendada;
    const realizada = cirugia?.info?.realizada;
    const aceptada = cirugia?.info?.aceptada;
    const suspendida = cirugia?.info?.suspendida;
    const rechazada = cirugia?.info?.rechazada;
    const asignado = cirugia?.especialista?.asignado;    
    const currentUserIsEC = user._id ===cirugia?.especialista?.nombre?._id;
    const currentUserIsVentas = user._id ===cirugia?.ventas?._id;
    const isCheckedIn = cirugia?.checkInFecha;
    const pendienteRespuestaAsignacion = !cirugia?.especialista?.confirmado && !cirugia?.especialista?.rechazado;
    const roleIsVentas = user.role.isVentas;

    //console.log(subscription.companyPermissions)

    const planAllowsCheckIn = subscription?.companyPermissions?.includes('checkIn');;
    
    const currentTime = new Date()
    const cirugiaTime = new Date(cirugia.info.fecha)
    const pastCirugiaScheduledTime = currentTime > cirugiaTime; 
    
    

    if(_cirugia){
        cirugia = _cirugia
    }

    const handleOnClick = (formType)=>{
        setOpenModal(true);
        setCirugiaId(cirugiaId)
        setFormType(formType)
    }

    //console.log(cirugia)
    

    //took out !pendienteRespuestasAsingacion to block suspender button - August 30, 2022

    
    // console.log(suspendida)
    // console.log(canCancelarCirugias)
    // console.log(roleIsVentas)
    // console.log(currentUserIsVentas)

    
    if(cirugia?.info?.cancelada && canReactivarCirugias){
        return(
        <ActionWrapper>
            <Button outline onClick={()=>handleOnClick('reactivar')}>Reactivar</Button>
        </ActionWrapper>
        )
    }else if(cirugia?.info?.cancelada && !canReactivarCirugias){
        return null;
    }



    return(
        <ActionWrapper>
            
            { 
                (suspendida || !agendada) && 
                canCancelarCirugias && 
                (roleIsVentas ? currentUserIsVentas : true) &&
                <IconButton onClick={()=>handleOnClick('cancelar')}>
                    <Trash2 size={20} color={theme.palette.error.light}/>    
                </IconButton>
            }
            
            {
                <IconButton onClick={()=>handleOnClick('aiChat')}>
                    <MessageCircle size={24} color={theme.palette.grey[700]}/>
                </IconButton>
            }
            
            {
                canAgendar && (suspendida || !agendada) && !realizada &&
                <IconButton onClick={()=>handleOnClick('checkAvailability')}>
                    <FontAwesomeIcon icon={faCalendarCheck} style={{fontSize: "1.25rem"}} color= {theme.palette.grey[700]}/>
                </IconButton>
            }
            
            <CopyCirugia cirugia={cirugia}/>

            { (cirugia?.evaluacion?.negativa || cirugia?.evaluacion?.positiva) &&
                <IconButton onClick={()=>handleOnClick('comentariosMd', cirugia._id)}>
                    <MessageSquare color={theme.palette.grey[600]}/>
                </IconButton>
            }

            {  agendada &&  !realizada && canSuspenderCirugias &&  !suspendida && !rechazada  &&  
                <Button outline error onClick={()=>handleOnClick('suspender')}>Suspender</Button>
            }

            { realizada && canEditReporte && (currentUserIsEC || isMaster) &&
                <ConsumoButtonGroup cirugia={cirugia} onClick={handleOnClick}/>
            }             
            
            { realizada && canResetReporte &&
                <ResetMenuButton
                setFormType={setFormType} 
                cirugiaId={cirugiaId}
                setCirugiaId={setCirugiaId}
                setOpenModal={setOpenModal}
                />
            }
            {
                realizada &&
                    <Button outline onClick={()=>handleOnClick('fotos')}>Fotos</Button>
            }
            
            {realizada && canUpdateFacturacionStages &&
                <IconButton onClick={()=>handleOnClick('facturacion')}>
                    <FileText size={24} color= {theme.palette.grey[700]}/>
                </IconButton>            
            }
            
            { agendada && canUpdateOperacionesStages &&
                <IconButton onClick={()=>handleOnClick('operaciones')}>
                    <Truck size={24} color={theme.palette.grey[700]}/>
                </IconButton>      
            }
            
            
            {
            ((agendada && asignado && currentUserIsEC && !isCheckedIn) || isMaster) && planAllowsCheckIn &&
                <IconButton onClick={()=>handleOnClick('confirmCheckIn')}>
                    <MapPin size={24} color={theme.palette.grey[700]}/>
                </IconButton>         
            }

            <IconButton onClick={()=>handleOnClick("productos")}>
                <FontAwesomeIcon icon={faDolly} style={{fontSize: "1.25rem"}} color= {theme.palette.grey[700]}/>
            </IconButton>

            {canAutorizarCirugias && !realizada && agendada &&
                <IconButton onClick={()=>handleOnClick("autorizar")}>
                    <CheckCircle color= {theme.palette.grey[700]} size="24"/>                    
                </IconButton>
            }


            {!realizada && canEditarCirugias &&
                <DropDownButton 
                    setFormType={setFormType} 
                    cirugiaId={cirugiaId}
                    setCirugiaId={setCirugiaId}
                    setOpenModal={setOpenModal}
                />
            }


            <VerMenuButton
                setFormType={setFormType} 
                cirugiaId={cirugiaId}
                setCirugiaId={setCirugiaId}
                setOpenModal={setOpenModal}
                currentUserIsEC={currentUserIsEC}
                currenUserIsVentas={currentUserIsVentas}
            />    
            
            { !realizada  && canAgendar && !agendada && 
                <Button success onClick={()=>handleOnClick('agendar')}>{"Agendar"}</Button>
            }
            
            {aceptada && !realizada &&  canAsignarEc && !suspendida &&
                <Button success onClick={()=>handleOnClick('asignar')}>{!asignado ? 'Asignar' : "Re-asignar"}</Button>
            }

            {(canConcluirCirugias || currentUserIsEC )  && agendada && aceptada && !realizada && !suspendida && asignado && (!pendienteRespuestaAsignacion || isMaster) && pastCirugiaScheduledTime &&
                <Button outline success onClick={()=>handleOnClick('concluir')}>Concluir</Button>
            }
            
            
            
            {
               agendada && !aceptada &&  !realizada && canAceptarCirugias && !rechazada && !suspendida &&
               <>
                    <Button outline error onClick={()=>handleOnClick('rechazar')}>Rechazar</Button>
                    <Button outline warning onClick={()=>handleOnClick('aceptar')}>Aceptar</Button>
                </>
            }

            {
                agendada && currentUserIsEC && pendienteRespuestaAsignacion && 
                <>
                    <Button outline error onClick={()=>handleOnClick('rechazarAsignacion')}>Rechazar</Button>
                    <Button outline warning onClick={()=>handleOnClick('aceptarAsignacion')}>Aceptar</Button>
                </>
                
            }
            
            
        </ActionWrapper>
    )
}



const ActionWrapper = styled.div`
    padding: 16px;    
    gap:8px; 
    display:flex;
    width:100%;
    justify-content: flex-end;
    align-items:center;
    flex-wrap: wrap;
`
const ActionContainer = styled.div`
    padding: 16px;
    display:flex;
    gap: 8px;
    
    justify-content: flex-end;
    align-items:center;
    flex-wrap: wrap;
`

const TrashWrapper = styled.div`
    
`



export default CirugiasActions