import Modal from "../Atoms/Modal";
import AgendarCirugia from '../Forms/AgendarCirugia';
import AsignarCirugia from '../Forms/AsignarCirugia';
import ConcluirCirugia from '../Forms/ConcluirCirugia';
import AceptarCirugia from '../Forms/AceptarCirugia';
import ModifyDoctor from '../Forms/ModifyDoctor';
import ModifyCentro from '../Forms/ModifyCentro';
import ModifyPago from '../Forms/ModifyPago';
import ModifyProductos from '../Forms/ModifyProductos';
import ModifyVentas from '../Forms/ModifyVentas';
import SuspenderCirugia from '../Forms/SuspenderCirugia';
import Documentos from '../Forms/ModifyDocuments'
import Comments from './Comments';
import Consumo from '../Forms/Consumo';
import AddProductos from '../Forms/AddProductos';
import EditarReporte from '../Forms/EditarReporte';
import Filtro from './Filtro';
import Archivos from "./Molecules/Archivos";
import Fotos from "./Molecules/Fotos";
import Facturacion from "./Molecules/Facturacion";
import Operaciones from "./Molecules/Operaciones";
import ConfirmCheckIn from "./Molecules/ConfirmCheckIn";
import CheckInResult from "./Molecules/CheckInResult";
import ProductTable from "./Molecules/ProductTable";
import ProductTableMd from "../md/cirugias/Molecules/ProductTableMd";
import ProductTableModal from "./Molecules/ProductTableModal";
import Reporte from "./Molecules/Reporte";
import Autorizar from "./Molecules/Autorizar";
import RechazarCirugia from "../Forms/RechazarCirugia";
import AceptarAsignacion from "../Forms/AceptarAsignacion";
import RechazarAsignacion from "../Forms/RechazarAsignacion";
import Comentarios from "../md/cirugias/Molecules/Comentarios";
import CancelarCirugia from "../Forms/CancelarCirugia";
import ReactivarCirugia from "../Forms/ReactivarCirugia";
import CheckAvailability from "../operaciones/molecules/CheckAvailability";
import AiChat from "../aiChat/AiChat";
import AddComment from "./Molecules/AddComment";
import PriorizarCirugia from "./Molecules/PriorizarCirugia";




const CirugiasModal = ({
        openModal,
        setOpenModal,
        inProp,
        setInProp,
        close,
        formType,
        cirugiaId,
        cirugia
})=>{

    const smallBottomSwipeDrawers = ["agendar", "asignar", "aceptar", "suspender", "modifyDoctor", "modifyCentro", 
                    "modifyPago", "modifyVentas", "confirmCheckIn", "autorizar"
                ]

      
                
    //console.log(openModal)
    return(
        <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}
            >
                {formType === "agendar" && <AgendarCirugia cirugiaId={cirugiaId} close={close}/>}
                {formType === "asignar" && <AsignarCirugia cirugiaId={cirugiaId} close={close}/>}
                {formType === "concluir" && <ConcluirCirugia cirugiaId={cirugiaId} close={close}/>}
                {formType === "suspender" && <SuspenderCirugia cirugiaId={cirugiaId} close={close}/>}
                {formType === "cancelar" && <CancelarCirugia cirugiaId={cirugiaId} close={close}/>}
                {formType === "reactivar" && <ReactivarCirugia cirugiaId={cirugiaId} close={close}/>}
                {formType === "aceptar" && <AceptarCirugia cirugiaId={cirugiaId} close={close}/>}
                {formType === "rechazar" && <RechazarCirugia cirugiaId={cirugiaId} close={close}/>}
                {formType === "modifyDoctor" && <ModifyDoctor cirugiaId={cirugiaId} close={close}/>}
                {formType === "modifyCentro" && <ModifyCentro cirugiaId={cirugiaId} close={close}/>}
                {formType === "modifyPago" && <ModifyPago cirugiaId={cirugiaId} close={close}/>}
                {formType === "modifyProductos" && <ModifyProductos cirugiaId={cirugiaId} close={close}/>}
                {formType === "modifyVentas" && <ModifyVentas cirugiaId={cirugiaId} close={close}/>}
                {formType === "documentos" && <Documentos cirugiaId={cirugiaId} close={close} cirugia={cirugia}/>}
                {formType === "comentarios" && <Comments cirugiaId={cirugiaId} close={close}/>}
                {formType === "consumo" && <Consumo cirugiaId={cirugiaId} close={close}/>}
                {formType === "addProducto" && <AddProductos deleteProducts={true} editCantidad={false} cirugiaId={cirugiaId} close={close}/>}
                {formType === "editarReporte" && <EditarReporte deleteProducts={true} editCantidad={false} cirugiaId={cirugiaId} close={close}/>}
                {formType === "filtro" && <Filtro close={close}/>}
                {formType === "archivos" && <Archivos cirugiaId={cirugiaId} close={close}/>}
                {formType === "fotos" && <Fotos cirugiaId={cirugiaId}/>}
                {formType === "facturacion" && <Facturacion cirugiaId={cirugiaId} close={close}/>}
                {formType === "operaciones" && <Operaciones cirugiaId={cirugiaId}/>}
                {formType === "confirmCheckIn" && <ConfirmCheckIn cirugiaId={cirugiaId} close={close}/>}
                {formType === "checkInResult" && <CheckInResult cirugiaId={cirugiaId} close={close}/>}
                {formType === "productos" && <ProductTableModal cirugiaId={cirugiaId}/>}
                {formType === "reporte" && <Reporte cirugiaId={cirugiaId} cirugia={cirugia}/>}
                {formType === "autorizar" && <Autorizar cirugiaId={cirugiaId} cirugia={cirugia} close={close}/>}
                {formType === "aceptarAsignacion" && <AceptarAsignacion cirugiaId={cirugiaId} close={close}/>}
                {formType === "rechazarAsignacion" && <RechazarAsignacion cirugiaId={cirugiaId} close={close}/>}
                {formType === "comentariosMd" && <Comentarios cirugiaId={cirugiaId} close={close}/>}
                {formType === "checkAvailability" && <CheckAvailability cirugiaId={cirugiaId} close={close}/>}
                {formType === "aiChat" && <AiChat cirugiaId={cirugiaId} close={close}/>}
                {formType === "addComment" && <AddComment cirugiaId={cirugiaId} close={close}/>}
                {formType === "priorizar" && <PriorizarCirugia cirugiaId={cirugiaId} close={close}/>}
                 
             </Modal>     

    )
}


export default CirugiasModal;