import styled from '@emotion/styled';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import React from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../Atoms/Button';
import IconButton from '../../Atoms/IconButton';
import ChevronLeft from '../../Icons/ChevronLeft'
import ChevronRight from '../../Icons/ChevronRight'
import TextInput from '../../Atoms/TextInput';
import useCirugia, { useCirugiaWithLoading } from '../../../hooks/useCirugia';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import { updateCirugia } from '../../../app/thunkActions/cirugiasActions';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../../app/messagesReducer';


const Facturacion = ({
    cirugiaId,
    close
})=>{
    const [activeStep, setActiveStep] = React.useState(0);
    const tablet= useMediaQuery('tablet');
    const dispatch = useDispatch();
    const [cirugia, isLoading] = useCirugiaWithLoading(cirugiaId);
    const [statusFacturacion, setStatusFacturacion] = React.useState(cirugia?.info?.statusFacturacion || "Transf.");
    
  

    const steps = React.useMemo(()=>[
        "Transf.",
        "Entrega",
        "Revision",
        "Cambio",
        "Cierre",
        "Facturada"
    ],[])

    
    const [formValues, setFormValues] = React.useState({
        transferencia: "",
        entrega:"",
        cierre:"",
        factura:""
      });
    
    const accionMaker = useAccionMaker()

    React.useEffect(() => {
        if (!cirugia) return;
        
        setFormValues({
            transferencia: cirugia?.facturacion?.transferencia || "",
            entrega: cirugia?.facturacion?.entrega || "",
            cierre: cirugia?.facturacion?.cierre || "",
            factura: cirugia?.facturacion?.factura || "",
        });

        // Set initial status and step based on saved data
        const savedStatus = cirugia?.info?.statusFacturacion;
        if (savedStatus) {
            setStatusFacturacion(savedStatus);
            console.log(savedStatus)
            if(savedStatus === "Cambio"){
                console.log("cambio")
                setActiveStep(3);
            }else{
                const stepIndex = steps.findIndex(step => step === savedStatus);
                if (stepIndex !== -1) {
                    setActiveStep(stepIndex);
                }
            }
        }
    }, [cirugia, steps]);

    React.useEffect(() => {
        if (cirugia?.info?.statusFacturacion) {
            setStatusFacturacion(cirugia.info.statusFacturacion);
        }
    }, [cirugia]);

    React.useEffect(() => {
        if (!formValues) return;

        let newStep = 0;
        let newStatus = statusFacturacion;
        
        if (formValues.transferencia) {
            newStep = 1;
            newStatus = steps[1];
        }
        
        if (formValues.entrega) {
            if (statusFacturacion === "Cambio") {
                newStep = 3;
                newStatus = "Cambio";
            }else if(statusFacturacion === "Cierre"){
                newStep = 4;
                newStatus = "Cierre";
            }else if(statusFacturacion === "Facturada"){
                console.log("facturada")
                newStep = 6;
                newStatus = "Facturada";
            }else {
                newStep = 2;
                newStatus = steps[2];
            }
        }
        
        if (formValues.cierre) {
            newStep = 5;
            newStatus = "Cerrada";
        }
        
        if (formValues.factura) {
            newStep = 6;
            newStatus = steps[5];
        }

        setActiveStep(newStep);
        setStatusFacturacion(newStatus);
        console.log(newStep)
        console.log(newStatus)
    }, [formValues, steps, statusFacturacion]);

    const onChangeValues = (event) => {
        const {name, value} = event.target;
        setFormValues(prevState => ({...prevState, [name]: value}));
    };

    const handleNext = () => {
        if (activeStep >= steps.length - 1) return;
        console.log(activeStep)
        console.log(steps[activeStep])

        if(activeStep ===0 ){
            if(formValues.transferencia){
                setActiveStep(1);
                setStatusFacturacion(steps[1]);
            }else{
                dispatch(errorMessage("Debes ingresar el numero de transferencia."));
                return;
            }
        }

        if(activeStep === 1){
            if(formValues.entrega){
                setActiveStep(2);
                setStatusFacturacion(steps[2]);
            }else{
                dispatch(errorMessage("Debes ingresar el numero de entrega."));
                return;
            }
        }

        if(activeStep === 4){
            if(formValues.cierre){
                setActiveStep(5);
                setStatusFacturacion(steps[5]);
            }else{
                dispatch(errorMessage("Debes ingresar el numero de cierre."));
                return;
            }
        }
       
        
        // Validate factura requirement for final step
        if (activeStep === 5 && !formValues.factura) {
            dispatch(errorMessage("Debes ingresar el numero de factura."));
            return;
        }

        // Allow progression through Revision and Cambio without field validation
        const nextStep = activeStep + 1;
        console.log(nextStep)
        console.log(steps[nextStep])
        setActiveStep(nextStep);
        setStatusFacturacion(steps[nextStep]);
    };

    const handleBack = () => {
        if (activeStep === 0) return;

        // Prevent moving back if fields are filled
        if (formValues.factura) {
            dispatch(errorMessage("Para retroceder, primero debes borrar el número de factura."));
            return;
        }
        
        if (formValues.cierre && activeStep <= 4) {
            dispatch(errorMessage("Para retroceder, primero debes borrar el campo de cierre."));
            return;
        }
        
        if (formValues.entrega && activeStep <= 1) {
            dispatch(errorMessage("Para retroceder, primero debes borrar el campo de entrega."));
            return;
        }
        
        if (formValues.transferencia && activeStep <= 0) {
            dispatch(errorMessage("Para retroceder, primero debes borrar el campo de transferencia."));
            return;
        }

        const prevStep = activeStep - 1;
        setActiveStep(prevStep);
        setStatusFacturacion(steps[prevStep]);
    };

    const onSubmit = () => {
        // Validate factura requirement for Facturada status
        if (statusFacturacion === "Facturada" && !formValues.factura) {
            dispatch(errorMessage("Debes ingresar el numero de factura para marcar como facturada."));
            return;
        }

        const accion = accionMaker(`Cambio status facturacion a ${statusFacturacion}`);
        
        const facturacionInfo = {
            cirugiaId,
            statusFacturacion,
            transferencia: formValues.transferencia,
            entrega: formValues.entrega,
            cierre: formValues.cierre,
            factura: formValues.factura,
            accion
        };

        dispatch(updateCirugia('facturacion', facturacionInfo));
    };

    return(
        <Wrapper>
            <Title>Facturacion</Title>
            <StepperWrapper>            
                <Stepper activeStep={activeStep} alternativeLabel={tablet?true:false}  orientation={tablet? "horizontal" : "vertical"}>
                    {
                        steps.map((step, index)=>(
                            <Step key={`${step} ${index}`}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </StepperWrapper>
            <FormWrapper>
                <TextInput label="Transferencia" id="transferencia" name="transferencia" value={formValues.transferencia} onChange={onChangeValues}/>
                <TextInput label="Entrega" id="entrega" name="entrega" value={formValues.entrega} onChange={onChangeValues}/>
                <TextInput label="Cierre" id="cierre" name="cierre" value={formValues.cierre} onChange={onChangeValues}/>
                <TextInput label="Factura" id="factura" name="factura" value={formValues.factura} onChange={onChangeValues}/>
            </FormWrapper>
            <Actions>
                    <Button error onClick={()=>close()}>Volver</Button>
                    <Controls>
                        <IconButton onClick={handleBack}>
                            <ChevronLeft/>
                        </IconButton>
                        <IconButton onClick={handleNext}>
                            <ChevronRight />
                        </IconButton>
                    </Controls>
                    <Button 
                        success
                        onClick={onSubmit}
                        loading={isLoading}
                    >Guardar</Button>
            </Actions>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction:column;   
    width:100%;
    padding: 24px 12px;
    

    @media ${p=>p.theme.media.tablet}{
        padding: 24px 36px 12px ;
    }
`

const StepperWrapper = styled.div`
    flex:2;
    width:100%;
    display:flex;
    justify-content: center;
    
`

const Title = styled.h1`
    margin:0;
    font-size: 1.5rem;
    margin-bottom: 36px;
    
`

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0px;
`

const Actions = styled.div`
    flex:1;
    max-height: 65px;
    display:flex;
    justify-content: space-between;
    align-items: center;
`

const Controls = styled.div`
    display:flex;
`

export default Facturacion