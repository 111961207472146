import Chip from '../../Atoms/Chip';
import { useTheme } from '@mui/system';
import Archive from '../../Icons/Archive';

const FacturacionChip = ({statusFacturacion})=>{
    const theme = useTheme()
    const color = theme.palette.grey[600]
    const fillArchiveColor = theme.palette.grey[300]
    
    if(!statusFacturacion) return null

    

    return(        
        <Chip icon={<Archive size={18} color={color} fill={fillArchiveColor} /> } outline color={color} label={statusFacturacion} />
    )
}



export default FacturacionChip;